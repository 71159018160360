.page-header{
    background-color: rgba(54,32,87,0.64);
    min-height: 260px;
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "Roboto-Bold";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 15px;
    margin-bottom: 158px;
}

@media (max-width: 768px) {
    .page-header{
        margin-bottom: 60px;
        padding: 20px 10px;
        font-size: 35px;
        line-height: 40px;
        min-height: 150px;
    }
}
