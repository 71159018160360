.header {
  background: #1C122B; }
  .header .container-fluid {
    display: flex;
    align-items: center;
    justify-content: center; }
  .header .menu-main {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .header .menu-main .menu-item {
      padding: 45px 3vw;
      color: #FFFFFF;
      font-family: "Roboto-Bold";
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      white-space: nowrap; }
      .header .menu-main .menu-item:after {
        content: url("../assets/img/menu-item-arrow.svg");
        width: 10px;
        height: 10px;
        margin-left: 8px; }
    .header .menu-main .menu-item:nth-of-type(n+6) {
      order: 2; }
    .header .menu-main .logo {
      background: #1c122b;
      order: 1;
      display: block;
      text-align: center;
      min-width: 144px;
      padding: 14px 2px;
      position: relative;
      box-shadow: 0px -6px 31px rgba(0, 0, 0, 0.56); }
      .header .menu-main .logo:after {
        content: "";
        -webkit-clip-path: polygon(49% 100%, 0 80%, 100% 80%);
        clip-path: polygon(49% 100%, 0 80%, 100% 80%);
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: -9px;
        left: 0;
        background: #1c122b; }
      .header .menu-main .logo img {
        width: 100%;
        max-width: 70px; }

footer {
  background: #362057;
  padding: 55px 0 85px 0; }
  footer .container-fluid .border-bottom {
    padding-bottom: 33px;
    border-bottom: 1px solid #6047B6 !important; }
  footer .container-fluid .logo {
    width: 106px;
    margin-right: 80px; }
  footer .container-fluid .footer-links {
    flex-grow: 1; }
    footer .container-fluid .footer-links .link:nth-last-child(1) {
      margin-right: 0; }
    footer .container-fluid .footer-links .link {
      font-family: "Roboto-Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-right: 84px; }
  footer .container-fluid .footer-copyright {
    padding-top: 33px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF; }

@media only screen and (max-width: 767px) {
  footer .container-fluid .border-bottom {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important; }
    footer .container-fluid .border-bottom .logo {
      margin-right: 0;
      margin-bottom: 20px; }
  footer .container-fluid .footer-links {
    flex-direction: column; }
    footer .container-fluid .footer-links .link {
      margin-right: 0;
      margin-bottom: 5px; } }

@media only screen and (max-width: 767px) {
  header {
    display: none; }
  header.mobile {
    display: block; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  header {
    display: none; }
  header.mobile {
    display: block; } }

.custom-radio .custom-control-label::before {
  border: #ef7921 solid 3px; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23EF7921'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ef7921;
  background-color: #ef7921; }

/* Chrome, Safari, Edge, Opera */
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #3D4864 !important; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.rtl input, .rtl textarea, .rtl select {
  direction: rtl !important; }

::-webkit-input-placeholder {
  /* Edge */
  color: #495872;
  text-transform: capitalize; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495872;
  text-transform: capitalize; }

::placeholder {
  color: #495872;
  text-transform: capitalize; }

.form-group {
  margin-bottom: 20px; }

.input-group.gold-border input, .input-group.gold-border textarea, .input-group.gold-border select {
  border: 1px solid #C29E63;
  border-radius: 4px; }

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px; }
  .input-group > label {
    font: normal normal normal 18px/21px Arial;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    margin-bottom: 6px;
    text-align: left;
    text-transform: capitalize; }
  .input-group input, .input-group textarea, .input-group select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    width: 100%;
    padding: 6.7px 20px;
    min-height: 44px;
    letter-spacing: 1.5px; }

.checkbox-group label {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.67;
  margin-left: 7px;
  margin-bottom: 0; }

input[type="checkbox"].custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #434343;
  background-color: #fff;
  border-radius: 0; }

.custom-checkbox input[type="checkbox"] + .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox input[type="checkbox"].custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23434343' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

@media only screen and (max-width: 767px) {
  .checkbox-group label {
    font: normal normal normal 13px/15px Arial;
    margin-left: 5px; }
  .input-group {
    margin-bottom: 12px; }
    .input-group > label {
      font: normal normal normal 13px/15px Arial;
      margin-bottom: 6px; }
    .input-group input, .input-group textarea, .input-group select {
      padding: 5px 15px;
      font: normal normal normal 13px/15px Arial;
      min-height: 30px; } }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  background-color: #707070; }

::-webkit-scrollbar {
  width: 10px;
  border-radius: 7px;
  background-color: #707070; }

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff; }

.scrollbar {
  overflow-y: auto; }

.overflow-start-750 {
  max-height: 750px;
  overflow-y: auto; }

@media only screen and (max-width: 767px) {
  .bg {
    min-height: 90px !important; }
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .bg {
    min-height: 120px !important; } }

.table-ui {
  margin-bottom: 1em; }
  .table-ui tbody:before {
    content: "-";
    display: block;
    line-height: 6px;
    color: transparent; }
  .table-ui .btn-small {
    font: normal normal normal 16px/18px Arial;
    min-width: 118px !important;
    padding: 3px 7px; }
  .table-ui .btn-black-cust {
    min-width: 133px;
    margin-left: 0;
    margin-right: 0; }
  .table-ui .MuiTableCell-stickyHeader, .table-ui .MuiTableCell-root {
    background-color: #fff;
    border: 1px solid #C39F6466;
    font: normal normal normal 16px/18px Arial;
    letter-spacing: 0px;
    color: #192C3B; }
  .table-ui .MuiTableCell-stickyHeader {
    padding: 5px 6px; }
  .table-ui .MuiTableCell-root {
    padding: 5px 6px; }
  .table-ui .MuiTableRow-root.expired > .MuiTableCell-root, .table-ui tr.expired {
    background-color: #dc3545;
    color: white; }

@media (min-width: 640px) {
  .mobile-table {
    display: none; } }

@media (max-width: 640px) {
  .mobile-table {
    display: block; }
  .web-table {
    display: none; }
  .responsiveTable td.pivoted:nth-child(7), .responsiveTable td.pivoted:nth-child(8) {
    min-height: 85px; }
  .table-ui .btn-black-cust {
    width: 100%;
    max-width: 100%; }
  .mobile-table .responsiveTable td.pivoted {
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word; }
  .mobile-table .responsiveTable table, .mobile-table .responsiveTable thead, .mobile-table .responsiveTable tbody, .mobile-table .responsiveTable th, .mobile-table .responsiveTable td, .mobile-table .responsiveTable tr {
    display: block; }
  .mobile-table .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333; }
  .mobile-table .responsiveTable tbody tr {
    margin-bottom: 10px;
    border: 1px solid #C39F6466; } }

.main-btn {
  background: #FF3E01;
  border-radius: 4px;
  opacity: 1;
  max-width: max-content;
  min-width: 126px;
  text-align: center;
  font-family: "Roboto-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  letter-spacing: 0px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 13px 15px; }
  .main-btn:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
    color: #fff !important; }
  .main-btn:focus {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7); }

.btn-gold {
  background: #C39F64 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  min-width: 134px;
  text-align: center;
  font: normal normal normal 17px/20px Arial;
  letter-spacing: 0px;
  color: #FFFFFF;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: capitalize;
  padding: 7px 15px; }
  .btn-gold:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.58);
    color: #fff !important; }
  .btn-gold:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.58); }

.btn-gold-border-cust {
  background: #fff;
  border: 2px solid #C39F64;
  border-radius: 4px;
  min-width: 164px;
  text-align: center;
  font: normal normal bold 20px/29px Arial;
  letter-spacing: 0px;
  color: #192C3B;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: capitalize;
  padding: 21px 15px; }
  .btn-gold-border-cust:hover {
    border: 2px solid #192C3B;
    background: #192C3B;
    color: #fff !important; }
  .btn-gold-border-cust:focus {
    border: 1px solid #192C3B;
    background: #192C3B;
    color: #fff !important; }

.btn-white-border-cust {
  background: #fff;
  border: 1px solid #192C3B;
  border-radius: 4px;
  min-width: 164px;
  text-align: center;
  font: normal normal normal 18px/21px Arial;
  letter-spacing: 0px;
  color: #000000;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: capitalize;
  padding: 12px 15px; }
  .btn-white-border-cust:hover {
    border: 1px solid #192C3B;
    background: #192C3B;
    color: #fff !important; }
  .btn-white-border-cust:focus {
    border: 1px solid #192C3B;
    background: #192C3B;
    color: #fff !important; }

.btn-white-gray {
  font-family: "Roboto";
  background: #fff;
  border-radius: 28px;
  min-width: 164px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #B4B4B4;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #B4B4B4; }
  .btn-white-gray:hover {
    border: 1px solid #B4B4B4;
    background: #B4B4B4;
    color: #fff; }

.btn-large {
  padding: 25px 24px; }

.btn-medium {
  padding: 17px 15px; }

.btn-small {
  padding: 9px 12px;
  font-size: 11px;
  border: 0;
  line-height: 12px;
  box-sizing: border-box; }

@media only screen and (max-width: 767px) {
  .btn {
    min-width: 130px;
    padding: 10px 11px;
    font: normal normal normal 15px/18px Arial; } }

body.modal-open {
  padding-right: 10px !important; }

.modal.no-header .modal-content {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 8px 7px 22px #00000029;
  border-radius: 4px; }

.modal.no-header .modal-header {
  border: 0;
  padding: 28px 19px; }
  .modal.no-header .modal-header .close {
    padding: 30px 30px 0 30px; }
    .modal.no-header .modal-header .close span {
      font-size: 40px;
      color: #000;
      opacity: 1; }

.modal.update_profile_modal .input-group {
  margin-bottom: 19px; }

.modal.update_profile_modal .btn-black-cust {
  max-width: 139px; }

.message_notification_modal .modal-content {
  min-height: 486px; }

.message_notification_modal .modal-header {
  z-index: 33; }

.message_notification_modal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -85px; }

.message_notification_modal .message-text {
  text-align: center;
  font: normal normal normal 20px/23px Arial;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #192C3B 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 28px 18px; }

.administer_modal .modal-content {
  max-width: 636px;
  margin: 0 auto; }

.get_contact_modal .modal-header {
  position: relative; }
  .get_contact_modal .modal-header h4 {
    font: normal normal bold 24px/29px Cera Pro;
    letter-spacing: 0px;
    color: #192C3B;
    text-align: center;
    width: 100%; }
  .get_contact_modal .modal-header .modal-close {
    position: absolute;
    right: 20px;
    top: 20px; }

.get_contact_modal .modal-content {
  max-width: 636px;
  margin: 0 auto; }
  .get_contact_modal .modal-content .btn-black-cust {
    background: #192C3B 0% 0% no-repeat padding-box;
    font: normal normal normal 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    padding: 23px 15px;
    border-radius: 0;
    border: 0; }

.prolong_contract .modal-header {
  position: relative; }
  .prolong_contract .modal-header h4 {
    font: normal normal bold 24px/29px Cera Pro;
    letter-spacing: 0px;
    color: #192C3B;
    text-align: center;
    width: 100%; }
  .prolong_contract .modal-header .modal-close {
    position: absolute;
    right: 20px;
    top: 20px; }

.book-meeting-modal .modal-body {
  padding: 0; }
  .book-meeting-modal .modal-body .tab-content {
    padding-right: 15px;
    padding-left: 15px; }

.booking_modal_success .message {
  padding: 2em 0;
  font: normal normal normal 20px/23px Arial;
  letter-spacing: 0px;
  color: #192C3B; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 856px;
    margin: 1.75rem auto; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  body.modal-open {
    padding-right: 0px !important; }
  .modal .modal-dialog {
    margin: 0 15px; } }

@media only screen and (max-width: 767px) {
  body.modal-open {
    padding-right: 0px !important; }
  .modal .modal-dialog {
    margin: 0 15px; }
  .message_notification_modal .modal-content {
    min-height: 386px; }
  .message_notification_modal .message-text {
    padding: 24px 16px;
    font: normal normal normal 18px/20px Arial; } }

.games-slider {
  padding: 0px 40px 0px 40px;
  background: #362057; }
  .games-slider .slick-prev:before, .games-slider .slick-next:before {
    display: none; }
  .games-slider .live-game {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #FF3E01;
    padding: 17px 32px 12px 32px; }
    .games-slider .live-game .live-icon {
      font-family: "Roboto-Bold";
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 14px; }
      .games-slider .live-game .live-icon img {
        margin-right: 6px;
        width: 10px; }
    .games-slider .live-game .competitors {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .games-slider .live-game .competitors .competitor:nth-last-child(1) {
        flex-direction: row-reverse; }
      .games-slider .live-game .competitors .competitor {
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center; }
        .games-slider .live-game .competitors .competitor img {
          width: 30px;
          height: 30px;
          margin: 0 3px; }
      .games-slider .live-game .competitors .vs {
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #664CC4;
        margin: 0 7px; }
    .games-slider .live-game .info {
      font-family: "Roboto-Bold";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: center; }
  .games-slider .future-games {
    padding: 17px 32px 0px 32px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #6047B6; }
    .games-slider .future-games .date {
      font-family: "Roboto-Bold";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 0 20px;
      margin-bottom: 16px; }
    .games-slider .future-games .games {
      display: flex;
      align-items: center; }
      .games-slider .future-games .games .game:nth-last-child(1) {
        border-right: 0; }
      .games-slider .future-games .games .game {
        border-right: 1px solid #664CC4;
        padding: 0 20px;
        min-width: 112px;
        text-align: left; }
        .games-slider .future-games .games .game .competitors {
          display: flex;
          flex-direction: column;
          margin-bottom: 8px; }
          .games-slider .future-games .games .game .competitors .competitor {
            font-family: "Roboto-Bold";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            margin-bottom: 4px; }
            .games-slider .future-games .games .game .competitors .competitor img {
              width: 20px;
              margin-right: 7px; }
        .games-slider .future-games .games .game .time {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #FF3E01; }

@media only screen and (max-width: 767px) {
  .games-slider .live-game {
    padding: 10px 25px; } }

.partners-slider {
  padding: 0px 40px 0px 40px; }
  .partners-slider .slick-prev:before, .partners-slider .slick-next:before {
    display: none; }
  .partners-slider .item {
    padding: 0 50px; }
    .partners-slider .item img {
      width: 100%; }

.news-slider {
  padding: 0px 40px 0px 40px; }
  .news-slider .slick-prev:before, .news-slider .slick-next:before {
    display: none; }
  .news-slider .item.news {
    padding: 0 55px; }
    .news-slider .item.news .main-img {
      margin-bottom: 13px; }
    .news-slider .item.news .data-social {
      margin-bottom: 14px; }
      .news-slider .item.news .data-social .data {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 141.19%;
        /* or 25px */
        text-transform: uppercase;
        color: #FFFFFF; }
      .news-slider .item.news .data-social .social span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin-right: 16px; }
      .news-slider .item.news .data-social .social .links a {
        margin-right: 16px; }
        .news-slider .item.news .data-social .social .links a:nth-last-child(1) {
          margin-right: 0; }
    .news-slider .item.news .desc {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      text-transform: uppercase;
      color: #FFFFFF; }

@media only screen and (max-width: 767px) {
  .news-slider .item.news {
    padding: 0 0px; }
    .news-slider .item.news .data-social .data {
      font-size: 16px;
      line-height: 20px; }
    .news-slider .item.news .data-social .social span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      margin-right: 10px; }
    .news-slider .item.news .data-social .social .links a {
      margin-right: 7px; }
      .news-slider .item.news .data-social .social .links a:nth-last-child(1) {
        margin-right: 0; }
    .news-slider .item.news .desc {
      font-size: 18px;
      line-height: 24px; } }

.schedules {
  display: flex;
  flex-direction: column; }
  .schedules .schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #6047B6;
    padding: 22px 0 27px 0; }
    .schedules .schedule:nth-last-child(1) {
      border-bottom: 0; }
    .schedules .schedule .time {
      font-style: normal;
      font-weight: 900;
      font-size: 44.3077px;
      line-height: 52px;
      /* identical to box height */
      text-transform: uppercase;
      color: #FFFFFF;
      display: flex;
      align-items: flex-end; }
      .schedules .schedule .time .timezone {
        margin-left: 10px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 22.1538px;
        line-height: 35px;
        /* identical to box height */
        text-align: right;
        text-transform: uppercase;
        color: #FF3E01; }
    .schedules .schedule .competitors {
      display: flex;
      margin-bottom: 8px; }
      .schedules .schedule .competitors .competitor:nth-last-child(1) > div {
        display: flex;
        flex-direction: row-reverse; }
      .schedules .schedule .competitors .competitor:nth-last-child(1) .stats {
        text-align: right; }
      .schedules .schedule .competitors .competitor {
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: 900;
        font-size: 44.3077px;
        line-height: 52px;
        /* identical to box height */
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        margin-bottom: 4px;
        flex-direction: column; }
        .schedules .schedule .competitors .competitor img {
          width: 98.46px; }
        .schedules .schedule .competitors .competitor > div {
          display: flex;
          align-items: center; }
        .schedules .schedule .competitors .competitor .stats {
          font-family: "Roboto";
          margin-top: 8.3px;
          font-style: normal;
          font-weight: normal;
          font-size: 22.1538px;
          line-height: 26px;
          /* identical to box height */
          text-transform: uppercase;
          color: #FF3E01; }
      .schedules .schedule .competitors .result {
        font-family: "Roboto-Bold";
        font-style: normal;
        font-weight: 900;
        font-size: 44.3077px;
        line-height: 52px;
        /* identical to box height */
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 0 14px;
        margin-top: 25px; }

@media only screen and (max-width: 767px) {
  .schedules .schedule {
    flex-direction: column;
    align-items: center; }
    .schedules .schedule .time {
      font-size: 30px;
      line-height: 40px; }
    .schedules .schedule .competitors {
      width: 100%;
      justify-content: center; }
      .schedules .schedule .competitors .competitor img {
        width: 40px; }
      .schedules .schedule .competitors .competitor, .schedules .schedule .competitors .result {
        font-size: 30px;
        line-height: 40px; } }

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
  color: #000; }

.react-date-picker__clear-button, .react-date-picker__calendar-button {
  display: none; }

.react-date-picker__wrapper {
  border: 0 !important; }

.dateSelect {
  width: 100%;
  border-bottom: 1px solid #FF3E01;
  padding: 17px 0; }
  .dateSelect .date input, .dateSelect .date .react-date-picker__inputGroup__divider, .dateSelect .date .react-date-picker__inputGroup__leadingZero {
    cursor: pointer;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 59px;
    text-transform: uppercase;
    color: #FF3E01;
    background: transparent;
    border: 0;
    max-width: 143px; }
  .dateSelect .date .react-datepicker__input-container:after {
    cursor: pointer;
    content: url("../assets/img/dateSekecticon.svg");
    margin-left: 30px;
    width: 22px;
    height: 41px; }

@media only screen and (max-width: 767px) {
  .dateSelect {
    padding: 17px 0; }
    .dateSelect .date input, .dateSelect .date .react-date-picker__inputGroup__divider, .dateSelect .date .react-date-picker__inputGroup__leadingZero {
      font-size: 44px;
      line-height: 50px; }
    .dateSelect .date .react-date-picker__inputGroup:after {
      cursor: pointer;
      content: url("../assets/img/dateSekecticon.svg");
      margin-left: 30px;
      width: 18px;
      height: 34px; } }

.filters.openFilter {
  width: 100%;
  max-width: 534px;
  transition: 500ms ease width; }

.filters {
  border-left: 1px solid #6047B6;
  transition: 500ms ease width;
  width: 200px; }
  .filters > div {
    border-bottom: 1px solid #6047B6;
    padding: 38px; }
  .filters .filter-title {
    cursor: pointer;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF; }
  .filters .MuiFormControlLabel-root {
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF; }

@media (max-width: 768px) {
  .filters {
    right: 0;
    position: fixed;
    top: 151px;
    background-color: #6047B6; }
    .filters .filter-title {
      font-size: 16px;
      line-height: 18px; }
    .filters > div {
      padding: 15px 10px; } }

.partners-section {
  background: rgba(28, 18, 43, 0.8);
  position: relative; }
  .partners-section:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../assets/img/OurPartners.svg");
    background-position: center;
    background-repeat: no-repeat; }

.video-poster {
  width: 100%;
  position: relative; }
  .video-poster .play-button {
    transition: 500ms ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .video-poster video {
    height: 60vh;
    width: 100%; }

.about {
  color: #FFFFFF; }
  .about .container-fluid .desc {
    line-height: 175.69%; }

.live-translation-section .live-game {
  display: flex;
  flex-direction: column;
  padding: 17px 32px 12px 32px; }
  .live-translation-section .live-game .live-icon {
    font-family: "Roboto-Bold";
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 14px; }
    .live-translation-section .live-game .live-icon img {
      margin-right: 6px;
      width: 10px; }
  .live-translation-section .live-game .competitors {
    display: flex;
    align-items: center;
    justify-content: center; }
    .live-translation-section .live-game .competitors .competitor:nth-last-child(1) {
      flex-direction: row-reverse; }
    .live-translation-section .live-game .competitors .competitor {
      font-family: "Roboto-Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 44.3077px;
      line-height: 52px;
      text-transform: uppercase;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center; }
      .live-translation-section .live-game .competitors .competitor img {
        width: 98px;
        height: 98px;
        margin: 0 5.5px; }
    .live-translation-section .live-game .competitors .vs {
      font-family: "Roboto-Bold";
      font-style: normal;
      font-weight: 900;
      font-size: 29.5385px;
      line-height: 35px;
      text-transform: uppercase;
      color: #6047B6;
      margin: 0 14.5px; }

@media only screen and (max-width: 767px) {
  .live-translation-section .live-game {
    padding: 0; }
    .live-translation-section .live-game .competitors .competitor {
      font-size: 30px;
      line-height: 40px; }
      .live-translation-section .live-game .competitors .competitor img {
        width: 40px;
        height: 40px; }
    .live-translation-section .live-game .competitors .vs {
      font-size: 30px;
      line-height: 40px; } }

.news-page .news-filter-section .title-results {
  text-transform: uppercase; }

.news-page .news-parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 70px;
  grid-column-gap: 54px; }
  .news-page .news-parent .news .main-img {
    margin-bottom: 13px; }
  .news-page .news-parent .news .data-social {
    margin-bottom: 14px; }
    .news-page .news-parent .news .data-social .news-red {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-align: right;
      letter-spacing: 0.165em;
      color: #FF3E01; }
    .news-page .news-parent .news .data-social .social span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      margin-right: 16px; }
    .news-page .news-parent .news .data-social .social .links a {
      margin-right: 16px; }
      .news-page .news-parent .news .data-social .social .links a:nth-last-child(1) {
        margin-right: 0; }
  .news-page .news-parent .news .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #FFFFFF; }

@media (max-width: 768px) {
  .news-page .news-filter-section .title-results {
    text-transform: uppercase;
    font-size: 25px; }
  .news-page .news-parent {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    margin-right: 0; }
    .news-page .news-parent .news .desc {
      font-size: 18px;
      line-height: 20px; } }

.ft-sz-20 {
  font-size: 20px;
  line-height: 25px; }

.rotate180 {
  transition: 300ms ease;
  transform: rotate(180deg); }

.main-color {
  color: #192C3B; }

.color-gold {
  color: #C39F65; }

.orange-color {
  color: #EF7921; }

.disabled {
  opacity: 0.4 !important; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #D1D1D1; }

.box-shadow {
  background: #FAFAFA;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.ft-sz-13 {
  font-size: 13px !important;
  line-height: 15px !important; }

.ft-sz-15 {
  font-size: 15px !important;
  line-height: 20px !important; }

.ft-sz-12 {
  font-size: 12px !important;
  line-height: 14px !important; }

.ft-sz-17 {
  font-size: 17px !important;
  line-height: 20px !important; }

.ft-sz-23 {
  font-size: 23px;
  line-height: 31px; }

.color-gray {
  color: #B4B4B4; }

.overflow-hidden {
  overflow: hidden; }

.cursor-pointer {
  cursor: pointer; }

.d-none {
  display: none !important; }

.color-black {
  color: #000000; }

.color-red {
  color: red; }

.flex-grow {
  display: flex;
  flex-grow: 1; }

.width200 {
  width: 200px; }

.height-auto {
  height: auto !important; }

.center-text {
  text-align: center; }

.marg-auto {
  margin: auto; }

.mx-wdt-100px {
  max-width: 100px; }

.mx-wdt-420px {
  max-width: 420px; }

.mx-wdt-740px {
  max-width: 740px; }

.mx-wdt-250px {
  max-width: 250px; }

.min-wdt-100px {
  min-width: 100px; }

.w-80 {
  width: 80%; }

.mx-hgt-100 {
  max-height: 100vh; }

.mx-wdt-100 {
  max-width: 100% !important; }

.brd-rds-4 {
  border-radius: 4px; }

.padding-20 {
  padding: 20px; }

.padding-r-20 {
  padding-right: 20px; }

.padding-l-20 {
  padding-light: 20px; }

.padding-t-60 {
  padding-top: 60px; }

.padding-t-80 {
  padding-top: 80px; }

.padding-b-60 {
  padding-bottom: 60px; }

.padding-b-20 {
  padding-bottom: 20px; }

.padding-b-40 {
  padding-bottom: 40px; }

.padding-b-80 {
  padding-bottom: 80px; }

.padding-b-140 {
  padding-bottom: 140px; }

.padding-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-160 {
  padding-top: 160px;
  padding-bottom: 160px; }

.mg-b-60 {
  margin-bottom: 60px; }

.mg-b-10 {
  margin-bottom: 10px; }

.mg-b-50 {
  margin-bottom: 50px; }

.mg-b-26 {
  margin-bottom: 26px; }

.mg-b-40 {
  margin-bottom: 40px; }

.mg-b-32 {
  margin-bottom: 32px; }

.mg-b-20 {
  margin-bottom: 20px; }

.mg-b-48 {
  margin-bottom: 48px; }

.mg-b-56 {
  margin-bottom: 56px; }

.mg-t-90 {
  margin-top: 90px; }

.mg-b-90 {
  margin-bottom: 90px; }

.mg-b-140 {
  margin-bottom: 140px; }

.mg-t-26 {
  margin-top: 26px; }

.mg-t-8 {
  margin-top: 8px; }

.mg-t-10 {
  margin-top: 10px; }

.mg-t-40 {
  margin-top: 40px; }

.mg-t-48 {
  margin-top: 48px; }

.mg-t-56 {
  margin-top: 56px; }

.mg-t-80 {
  margin-top: 80px; }

.mg-t-150 {
  margin-top: 150px; }

.mg-t-40 {
  margin-top: 40px; }

.mr-24 {
  margin-right: 24px; }

.mr-10 {
  margin-right: 10px; }

.mr-30 {
  margin-right: 30px; }

.mr-40 {
  margin-right: 40px; }

.max-248 {
  max-width: 248px; }

.max-475 {
  max-width: 475px; }

.w-40 {
  width: 40%; }

.w-60 {
  width: 60%; }

.w-max-content {
  width: max-content; }

.fade.show {
  z-index: 999999; }

.border-radius8 {
  border-radius: 8px; }

.z-index-1 {
  z-index: 1; }

.title-bordered {
  text-align: left;
  font: normal normal bold 24px/29px Cera Pro;
  letter-spacing: 0px;
  color: #C39F64;
  position: relative;
  width: max-content; }
  .title-bordered::after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: #192C3B;
    position: absolute;
    bottom: -4.5px;
    left: 0;
    border-radius: 3px; }

.error {
  color: red;
  font-size: 14px; }

.success {
  color: green;
  font-size: 14px; }

.validation-messages {
  min-height: 20px; }

.big-background-image {
  background: rgba(28, 18, 43, 0.8);
  position: relative; }
  .big-background-image:after {
    content: "";
    background-image: url("../assets/img/big-background-image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

@media only screen and (max-width: 767px) {
  .empty {
    display: none; }
  .w-max-content {
    width: 100%; }
  .max-475 {
    max-width: 100%; }
  .w-80, .w-60, .w-40 {
    width: 100%; }
  .w-75 {
    width: 100% !important; }
  .max-height-100 {
    max-height: auto; }
  .padding-t-60 {
    padding-top: 40px; }
  .padding-t-80 {
    padding-top: 50px; }
  .padding-b-60 {
    padding-bottom: 40px; }
  .padding-b-80 {
    padding-bottom: 50px; }
  .padding-60 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .padding-80 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .padding-160 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .mg-b-60 {
    margin-bottom: 50px; }
  .mg-b-50 {
    margin-bottom: 45px; }
  .mg-b-32 {
    margin-bottom: 32px; }
  .mg-b-40 {
    margin-bottom: 35px; }
  .mg-b-48 {
    margin-bottom: 40px; }
  .mg-b-56 {
    margin-bottom: 50px; }
  .mg-t-32 {
    margin-top: 28px; }
  .mg-t-40 {
    margin-top: 35px; }
  .mg-t-48 {
    margin-top: 40px; }
  .mg-t-56 {
    margin-top: 50px; }
  .mg-t-80 {
    margin-top: 60px; }
  .mg-t-90 {
    margin-top: 70px; }
  .mg-b-90 {
    margin-bottom: 70px; }
  .mg-t-40 {
    margin-top: 35px; }
  .mr-20 {
    margin-right: 15px; }
  .mr-40 {
    margin-right: 35px; }
  .ft-sz-15 {
    font-size: 13px !important;
    line-height: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .max-475 {
    max-width: 100%; }
  .w-80, .w-40 {
    width: 100%; }
  .max-height-100 {
    max-height: auto; }
  .padding-t-60 {
    padding-top: 50px; }
  .padding-t-80 {
    padding-top: 50px; }
  .padding-b-60 {
    padding-bottom: 50px; }
  .padding-b-80 {
    padding-bottom: 50px; }
  .padding-60 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .padding-80 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .mg-b-60 {
    margin-bottom: 60px; }
  .mg-b-50 {
    margin-bottom: 50px; }
  .mg-b-32 {
    margin-bottom: 32px; }
  .mg-b-40 {
    margin-bottom: 40px; }
  .mg-b-48 {
    margin-bottom: 48px; }
  .mg-b-56 {
    margin-bottom: 56px; }
  .mg-t-32 {
    margin-top: 32px; }
  .mg-t-40 {
    margin-top: 40px; }
  .mg-t-48 {
    margin-top: 48px; }
  .mg-t-56 {
    margin-top: 56px; }
  .mg-t-80 {
    margin-top: 80px; }
  .mg-t-40 {
    margin-top: 40px; }
  .mr-20 {
    margin-right: 20px; }
  .mr-40 {
    margin-right: 40px; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  color: #fff; }

img {
  max-width: 100%; }

button {
  outline: none !important; }

a:hover {
  color: #000; }

button:focus {
  outline: none !important; }

main {
  min-height: 100vh; }

body {
  background: #FAFAFA; }

.two-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .two-flex > div {
    width: 45%; }
  .two-flex > div:nth-last-child(1) {
    margin-right: 0; }

.three-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .three-flex > div {
    width: 32%; }
  .three-flex > div:nth-last-child(1) {
    margin-right: 0; }

.four-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .four-flex > div {
    width: 24%; }
  .four-flex > div:nth-last-child(1) {
    margin-right: 0; }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .four-flex > div {
    width: 49% !important;
    margin-bottom: 25px; }
  .four-flex > div:nth-last-child(-n+2) {
    margin-bottom: 0; }
  .two-flex > div {
    width: 100% !important;
    margin: 0;
    margin-bottom: 25px; }
  .two-flex > div:nth-last-child(1) {
    margin-bottom: 0; }
  .three-flex > div {
    width: 100% !important;
    margin: 0;
    margin-bottom: 25px; }
  .three-flex > div:nth-last-child(1) {
    margin-bottom: 0; }
  .tablet-unset-two.two-flex > div {
    width: 45% !important;
    margin-bottom: 0; }
  .tablet-unset-two.two-flex > div:nth-last-child(1) {
    margin-right: 0 !important; } }

@media only screen and (max-width: 767px) {
  div.mobile-two-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    div.mobile-two-flex > div {
      width: 45% !important; }
    div.mobile-two-flex > div:nth-last-child(1) {
      margin-right: 0 !important; }
  .two-flex > div, .four-flex > div {
    width: 100% !important;
    margin: 0;
    margin-bottom: 20px; }
  .two-flex > div:nth-last-child(1), .four-flex > div:nth-last-child(1) {
    margin-bottom: 0; }
  .three-flex > div {
    width: 100% !important;
    margin: 0;
    margin-bottom: 20px; }
  .three-flex > div:nth-last-child(1) {
    margin-bottom: 0; } }

.container-fluid {
  width: 100%;
  max-width: 1592px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto; }

.tab-container {
  width: 100%;
  max-width: 1330px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto Medium';
  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Light';
  src: url("../assets/fonts/Roboto-Light.ttf") format("truetype"); }

body {
  font-family: 'Roboto'; }

.bold-family {
  font-family: "Roboto-Bold"; }

b, strong {
  font-family: "Roboto-Bold"; }

ul {
  list-style: none;
  margin-bottom: 0; }

a, a:hover {
  text-decoration: none; }

@media only screen and (min-width: 1201px) and (min-width: 1200px) {
  body {
    font-size: 24px;
    line-height: 27px; }
    body h1 {
      font-weight: 400;
      font-size: 80px;
      line-height: 90px; }
    body h2 {
      font-weight: 900;
      font-size: 58px;
      line-height: 68px; }
    body h3 {
      font-weight: 400;
      font-size: 28px;
      line-height: 48px; }
    body h4 {
      font-size: 22px;
      line-height: 28px; }
    body p, body li, body span, body button {
      font-size: 21px;
      line-height: 25px; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  body {
    font-size: 17px;
    line-height: 22px; }
    body h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px; }
    body h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px; }
    body h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 48px; }
    body h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px; }
    body p, body li, body span, body button {
      font-size: 16px;
      line-height: 21px; } }

@media only screen and (max-width: 767px) {
  body {
    font-size: 17px;
    line-height: 22px; }
    body h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px; }
    body h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px; }
    body h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px; }
    body h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px; }
    body p, body li, body span, body button {
      font-size: 18px;
      line-height: 21px; } }

.three-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px; }

.four-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-column-gap: 20px;
  grid-row-gap: 20px; }

.two-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 107px;
  grid-row-gap: 29px;
  position: relative; }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .three-grid {
    grid-template-columns: 1fr 1fr 1fr; }
  .four-grid {
    grid-template-columns: 1fr 1fr; } }

@media only screen and (max-width: 767px) {
  .three-grid {
    grid-template-columns: 1fr; }
  .four-grid {
    grid-template-columns: 1fr; }
  .two-grid {
    grid-template-columns: 1fr; } }
